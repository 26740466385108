<template>
   <div>
      <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
      </v-breadcrumbs>
      <v-card class="mx-auto" outlined tile color="#F8F8FF">
         <v-card-title>New Message Blast</v-card-title>
         <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
             <v-overlay
            absolute
            :value="overlay"
            >
            <v-progress-circular
               indeterminate
               color="primary"
               ></v-progress-circular>
         </v-overlay>
            <v-container class="fill-height"
               fluid>
               <v-row>
                  <v-col cols="12" md="12">
                     <v-autocomplete
                        dense
                        outlined
                        label="Notification Method *"
                        v-model="form.method.selected"
                        :items="form.method.items"
                        @change="imgValidate(form.method.selected); form.body.item = ''"
                        item-text="name"
                        item-value="id"
                        :rules="form.method.rule"
                        :loading="form.method.loader"
                        hide-details="auto"
                        background-color="white"
                        >
                     </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12" v-if="form.method.selected === 3">
                     <v-autocomplete
                        dense
                        outlined
                        label="Message Template Name *"
                        v-model="form.message_template.selected"
                        @change="getWhatsAppMessageBody"
                        :items="form.message_template.items"
                        item-text="name"
                        item-value="ID"
                        :rules="form.message_template.rule"
                        :loading="form.message_template.loader"
                        hide-details="auto"
                        background-color="white"
                        >
                     </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12" v-if="form.method.selected === 1">
                     <v-autocomplete
                        dense
                        outlined
                        label="Message Category *"
                        v-model="form.category.selected"
                        :items="form.category.items"
                        item-text="name"
                        item-value="categoryid"
                        :rules="form.category.rule"
                        :loading="form.category.loader"
                        hide-details="auto"
                        background-color="white"
                        >
                     </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12" v-if="form.method.selected === 1">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.title.item"
                        :rules="form.title.rule"
                        label="Message Title *"
                        required
                        hide-details="auto"
                        background-color="white"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" v-if="form.method.selected === 1">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.subtitle.item"
                        :rules="form.subtitle.rule"
                        label="Subtitle *"
                        required
                        hide-details="auto"
                        background-color="white"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" v-if="form.method.selected === 2">
                    <v-textarea
                        dense
                        outlined
                        v-model="form.bodysms.item"
                        :rules="form.bodysms.rule"
                        auto-grow
                        label="Message Body *"
                        required
                        hint="Message body not more than 160 characters including space."
                        persistent-hint
                        counter="160"
                        hide-details="auto"
                        background-color="white"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" style="z-index: 1" v-if="form.method.selected !== 2">
                    <span class="text-body-2">Message Body *</span>
                    <text-editor v-if="componentloader" v-model="form.body.item" :value="form.body.item" :color="'white'"></text-editor>
                    <v-input
                        error
                        readonly
                        v-if="componentloader"
                        :key="key"
                        v-model="form.body.item"
                        :rules="form.body.rule"
                        height="0"
                        class="hide-input-text"
                        hide-details="auto"
                        :hint="bodyhint"
                        persistent-hint
                    >
                    </v-input>
                  </v-col>
                  <v-col cols="12" md="12" v-if="form.method.selected === 1">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.url.item"
                        :rules="form.url.rule"
                        label="URL"
                        required
                        hide-details="auto"
                        background-color="white"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" v-if="form.method.selected === 1">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-file-input outlined prepend-icon="" dense persistent-hint :hint="imghint" background-color="white" label="Image *" v-model="form.image.file" :rules="form.image.imgrule" type="file" accept="image/*"></v-file-input>
                                <v-card class="mb-2" style="height: 300px; z-index: 1" outlined @drop.prevent="addDropFile" @dragover.prevent>
                                    <v-overlay
                                    :absolute="true"
                                    opacity="1"
                                    color="grey"
                                    :value="form.image.image == '' || form.image.image == undefined ? true : false"
                                    >
                                    <v-row>
                                        <v-col style="text-align: center">
                                            <p>Drag & Drop or Select Image from Folder</p>
                                            <v-icon x-large>fa fa-image</v-icon>
                                        </v-col>
                                    </v-row>
                                    </v-overlay>
                                    <img :src="form.image.image" :style="{'max-width': '100%', 'max-height': '100%'}" />
                                    <v-overlay
                                    :absolute="true"
                                    :value="form.image.btn_loading"
                                    >
                                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                                    </v-overlay>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                  <v-col cols="12" md="12">
                     <v-autocomplete
                        dense
                        outlined
                        label="Subscriber Type"
                        v-model="form.subscriber_type.selected"
                        @change="execFilter('subscriber_type')"
                        :items="form.subscriber_type.items"
                        item-text="name"
                        item-value="id"
                        :rules="form.subscriber_type.rule"
                        :loading="form.subscriber_type.loader"
                        hide-details="auto"
                        background-color="white"
                        >
                     </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12">
                    <span class="text-body-2">Choose Subscriber</span>
                    <v-checkbox dense hide-details @change="selectAll" class="col-5 pl-0" v-model="select_all">
                        <template v-slot:label class="checkbox-role"><span style="width: 100%">All Subscriber</span></template>
                    </v-checkbox>
                </v-col>
                <v-col cols="12" md="12">
                    <span class="text-body-2">Or select individually from below</span>
                </v-col>
                <v-col cols="12" md="6" v-if="form.subscriber_type.selected === 1">
                    <v-autocomplete
                            dense
                            outlined
                            placeholder="All Operators"
                            label="Operator"
                            v-model="operator.selected"
                            @change="execFilter('operator')"
                            :items="operator.items"
                            item-text="name"
                            item-value="OperatorID"
                            :rules="operator.rule"
                            :loading="operator.loader"
                            :disabled="operator.disable"
                            hide-details
                            >
                            </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" v-if="form.subscriber_type.selected === 1">
                    <v-text-field label="Search Subscriber" outlined class="search-textfield" hide-details dense v-model="search_subscriber"></v-text-field>
                </v-col>
                <v-col cols="12" offset-md="6" md="6" v-if="form.subscriber_type.selected === 2">
                    <v-text-field label="Search Subscriber" outlined class="search-textfield" hide-details dense v-model="search_subscriber"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" v-if="form.subscriber_type.selected === 1">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Car Parks"
                    label="Car Park"
                    v-model="carpark.selected"
                    @change="execFilter('carpark')"
                    :items="carpark.items"
                    item-text="name"
                    item-value="CarParkID"
                    :rules="carpark.rule"
                    :loading="carpark.loader"
                    :disabled="carpark.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        :headers="form.subscriber.header"
                        v-model="form.subscriber.selected"
                        :items="form.subscriber.items"
                        :loading="form.subscriber.loader"
                        show-select
                        item-key="loginMobileID"
                        hide-default-footer
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        dense
                        @toggle-select-all="removeSelectAll"
                        disable-pagination
                        v-scroll:#virtual-scroll-table-op="onScroll"
                        id="virtual-scroll-table-op"
                    >
                    <template
                        v-if="start > 0"
                        v-slot:body.prepend
                    >
                        <tr>
                        <td
                            :colspan="form.subscriber.header.length"
                            :style="'padding-top:'+startHeight+'px'"
                        >
                        </td>
                        </tr>
                    </template>
                    <template
                        v-if="start + perPage < form.subscriber.items.length"
                        v-slot:body.append
                    >
                        <tr>
                        <td
                            :colspan="form.subscriber.items.length"
                            :style="'padding-top:'+endHeight+'px'"
                        >
                        </td>
                        </tr>
                    </template>
                    </v-data-table>
                    <v-input
                        error
                        readonly
                        v-model="form.subscriber.selected"
                        :rules="form.subscriber.rule"
                        height="0"
                        class="hide-input-text mt-2"
                    >
                    </v-input>
                </v-col>
                <v-col cols="12" md="12" v-if="form.method.selected === 2 || form.method.selected === 3">
                    <v-text-field
                        dense
                        outlined
                        v-model="form.nonsubscriberphone.item"
                        :rules="form.nonsubscriberphone.rule"
                        label="Non Subscriber Phone No."
                        required
                        hide-details="auto"
                        background-color="white"
                        persistent-hint
                        hint="e.g. 60121230000, 6012123111"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-btn class="mt-4" tile small color="primary" type="submit" :loading="btn_loading"><v-icon left x-small>fa fa-save</v-icon> Save</v-btn>
                </v-col>
               </v-row>
            </v-container>
         </v-form>
      </v-card>
      <v-snackbar
         v-model="swal.notification"
         top
         :color="swal.scolor"
         >
         {{ swal.message }}
      </v-snackbar>
      <alert :dialog="ma.alertmodal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
   </div>
</template>

<script>
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import * as moment from "moment";
import * as tz from "moment-timezone";
import TextEditor from '../TextEditor';

export default {
    name: 'New Message Blast',
    title: 'New Message Blast',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Message',
                disabled: true,
                href: '#',
                },

                {
                text: 'Push Notification',
                disabled: true,
                href: '#',
                },

                {
                text: 'New Message Blast',
                disabled: true,
                href: '#',
                },
            ],
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            overlay: false,
            form: {
                method: {items: [
                    {id: 1, name: 'Push Notification'},
                    {id: 2, name: 'SMS'},
                    {id: 3, name: 'WhatsApp'}
                ], rule: [], selected: 1, loader: false, disable: false},

                category: {items: [], itemsFull: [], rule: [], selected: 1, loader: false, disable: false},
                title: {item: '', rule: []},
                subtitle: {item: '', rule: []},
                body: {item: '', rule: []},
                bodysms: {item: '', rule: []},
                url: {item: '', rule: []},
                image: {file: '', imgrule: [], image: '', btn_loading: '', logo: '' },
                subscriber_type: {items: [
                    {id: 1, name: 'Season'},
                    {id: 2, name: 'Casual'},
                ], rule: [], selected: 1, loader: false, disable: false},
                subscriber: {header: [
                        { text: '#', value: 'row', width: '5%' },
                        { text: 'CP ID', value: 'CarParkID', width: '5%' },
                        { text: 'Car Park', value: 'CarParkName', width: '15%' },
                        { text: 'Mobile ID', value: 'loginMobileID', width: '5%' },
                        { text: 'Name', value: 'name' },
                        { text: 'Mobile No.', value: 'mobile', width: '20%' },
                    ],
                    items: [], itemsFull: [], rule: [], selected: [], loader: false, disable: false},
                message_template: {items: [], itemsFull: [], rule: [], selected: 1, loader: false, disable: false},
                nonsubscriberphone: {item: '', rule: []}
            },
            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                items: [],
                itemsFull: [],
                itemsFull2: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            ma: {
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,
                alertmodal: false,
            },
            componentloader: false,
            search_subscriber: '',
            select_all: false,

            start: 0,
            timeout: null,
            rowHeight: 10,
            perPage: 10,
            imghint: 'Horizontal: 600 Vertical: 300',
            bodyhint: '',
            key: 1,
            pages: 0,
            page: 1,
            removed: [
                { text: 'CP ID', value: 'CarParkID', width: '5%' },
                { text: 'Car Park', value: 'CarParkName', width: '15%' }
            ]
        }
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.getSubscriberFull();
        }
        },

        'search_subscriber': {
        handler: function(value) {
            this.searchTimeOut();
        }
        }
    },

    components: {alert, 'text-editor': TextEditor},

    async created() {
        this.componentloader = true;
        await this.getMessageTemplate();
        await this.getMessageCategory();
        this.$watch(() => this.form.image.file, this.handleImageMain, {deep: true});

        await this.getOperatorFull();
        await this.getCarParkFull();
        await this.getSubscriberFull(1);
        this.$watch(() => this.form.subscriber.selected, function(value) {
            if (value.length > 0){
                this.select_all = false;
            }
        });
    },

    computed: {
        itemsLimited() {
        return this.form.subscriber.items.slice(this.start, this.perPage+this.start);
        },
        startHeight() {
        return this.start * this.rowHeight - 32;
        },
        endHeight() {
        return this.rowHeight * (this.form.subscriber.items.length - this.start);
        },
    },

    methods: {
        async getSubscriberFull(pg){
            this.form.subscriber.items = [];
            this.form.subscriber.itemsFull = [];
            this.form.subscriber.loader = true;

            try{
                let response = {};
                let recordCount = null;
                if (this.form.subscriber_type.selected === 1) {
                    response = await this.$store.dispatch("getSeasonSubscriberTable", {page: pg > 0 ? this.page = pg : this.page, operatorid: this.operator.selected, carparkid: this.carpark.selected, search: this.search_subscriber });
                    recordCount = response.data.recordcount[0].resultcount;
                }
                else if (this.form.subscriber_type.selected === 2) {
                    response = await this.$store.dispatch("getCasualSubscriberTable", {page: pg > 0 ? this.page = pg : this.page, search: this.search_subscriber });
                    recordCount = response.data.recordcount;
                }
                let dt = response.data.record;

                this.pages = Math.ceil(recordCount / 100);
                
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                        
                        if (this.form.subscriber_type.selected === 1 && dt[i].SystemID === 2){
                            dt[i].name = dt[i].name || "N/A";
                            this.form.subscriber.items.push(dt[i]);
                            this.form.subscriber.itemsFull.push(dt[i]);
                        }

                        else{
                            dt[i].name = dt[i].name || "N/A";
                            this.form.subscriber.items.push(dt[i]);
                            this.form.subscriber.itemsFull.push(dt[i]);
                        }
                    }
                }
            }

            catch(err){
                console.log(err);
            }

            this.form.subscriber.loader = false;
        },

        async getMessageTemplate(){
            try{
                let response = await this.$store.dispatch("getMessageTemplate", {ApprovedByTwilio: true});
                let dt = response.data.record;
                
                if (response.data.code == "AP000"){
                    this.form.message_template.items.push({name: 'Select', ID: ''});
                    this.form.message_template.itemsFull.push(dt[i]);

                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].ID} - ${dt[i].TemplateName}`;
                        this.form.message_template.items.push(dt[i]);
                        this.form.message_template.itemsFull.push(dt[i]);
                    }

                    this.form.message_template.selected = "";
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getWhatsAppMessageBody(){
            if (this.form.message_template.selected === "") return;

            this.overlay = true;
            this.componentloader = false;
            try{
                let response = await this.$store.dispatch("getWhatsAppMessageBody", {ID: this.form.message_template.selected});
                let dt = response.data.record;
                
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        this.form.body.item = dt[i].TemplateText;
                    }
                }

                this.overlay = false;
                this.componentloader = true;
            }

            catch(err){
                console.log(err);
            }
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.operator.items.push({name: "All Operators", OperatorID: ""});
                    this.operator.itemsFull.push({name: "All Operators", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;

                if (this.operator.selected){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.operator.selected;
                    });

                    this.carpark.items.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                        this.carpark.itemsFull.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 2){
                            dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                            this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                            this.carpark.itemsFull.push(dt[i]);
                        }
                    }

                    this.carpark.selected = this.carpark.items[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getMessageCategory(){
            let list = [];
            this.form.category.items = [];
            this.form.category.selected = "";

            try{
                this.form.category.loader = true;
                this.form.category.disable = true;

                let response = await this.$store.dispatch("getMessageCategory", {search: '', rowresultsperpage: 'null', notiTypeID: 1});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.form.category.items.push({name: "Select", categoryid: ""});
                    this.form.category.itemsFull.push({name: "Select", categoryid: ""});

                    dt.forEach((item) => {
                        item.name = `${item.categoryid} - ${item.categoryname}`;
                        this.form.category.items.push({name: item.name, categoryid: item.categoryid});
                        this.form.category.itemsFull.push(item);
                    });

                    this.form.category.selected = this.form.category.items[0].categoryid;
                }

                this.form.category.loader = false;
                this.form.category.disable = false;
            }

            catch(err){
                console.log(err);
            }
        },

        async addPushNotification(){
            this.btn_loading = true;
            try{
                let req = {
                    loginmobileids: this.select_all ? [] : this.form.subscriber.selected.map(itm => itm.loginMobileID),
                    title: this.form.title.item,
                    subtitle: this.form.subtitle.item,
                    message: this.form.body.item,
                    notificationcategoryid: this.form.category.selected,
                    url: this.form.url.item || null,
                    image: this.form.image.image
                };

                console.log('req', req);

                let response = await this.$store.dispatch("createBlastPushNotification", req);
                if (response.data.code === 'AP000'){
                    this.$router.push({ name: 'Inbox', query: {created: true}});
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This notification is already created`;
                }

                else{
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.btn_loading = false;
            }

            catch(err){
                console.log(err);
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.btn_loading = false;
            }
        },

        async addWhatsApp(){
            this.btn_loading = true;
            let subscribersphone = this.select_all ? [] : this.form.subscriber.selected.map(itm => itm.mobile);
            let nonsubscriberphone = this.form.nonsubscriberphone.item.split(',').map(item=>item.trim());
            let allphonenumbers = subscribersphone.concat(nonsubscriberphone);

            try{
                let req = {
                    phonenumbers: allphonenumbers,
                    templateID: this.form.message_template.selected,
                    placeholdersValues: this.processPlaceholderValues(this.form.body.item),
                    image: null
                };

                console.log('req', req);

                let response = await this.$store.dispatch("createBlastWhatsApp", req);
                if (response.data.code === 'AP000'){
                    this.$router.push({ name: 'WhatsApp', query: {created: true}});
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This notification is already created`;
                }

                else{
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.btn_loading = false;
            }

            catch(err){
                console.log(err);
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.btn_loading = false;
            }
        },

        processPlaceholderValues(text){
            let matches = text.match(/(?<=\{\{).*?(?=\}\})/g);
            return matches.filter(itm => itm !== "");
        },

        async addSMS(){
            this.btn_loading = true;
            let subscribersphone = this.select_all ? [] : this.form.subscriber.selected.map(itm => itm.mobile);
            let nonsubscriberphone = this.form.nonsubscriberphone.item.split(',').map(item=>item.trim());
            let allphonenumbers = subscribersphone.concat(nonsubscriberphone);

            try{
                let req = {
                    phonenumbers: allphonenumbers,
                    message: this.form.bodysms.item,
                };

                console.log('req', req);

                let response = await this.$store.dispatch("createBlastSMS", req);
                if (response.data.code === 'AP000'){
                    this.$router.push({ name: 'SMS', query: {created: true}});
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This notification is already created`;
                }

                else{
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.btn_loading = false;
            }

            catch(err){
                console.log(err);
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = 'Something went wrong. Please try again later';
                this.btn_loading = false;
            }
        },

        async beforeCreateForm(flag){
            this.form.subscriber.rule = [];
            this.form.body.rule = [];

            this.form.category.rule = [
                v => !!v || 'This is required'
            ];

            this.form.title.rule = [
                v => !!v || 'This is required'
            ];

            this.form.subtitle.rule = [
                v => !!v || 'This is required'
            ];

            let body2 = /^.*>[^<].*$/.test(this.form.body.item);
            this.form.body.rule = [
                v => !!v || 'This is required',
                v => !v || body2 || 'This is required',
            ];

            this.form.bodysms.rule = [
                v => !!v || 'This is required',
                v => v.length < 160 || 'Please ensure message body not more than 160 characters including space.'
            ];

            this.form.message_template.rule = [
                v => !!v || 'This is required'
            ];

            this.form.nonsubscriberphone.rule = [
                v => !v || /^(6{1}0)[0-9]{1,2}[0-9]{7,8}(,\s?(6{1}0)[0-9]{1,2}[0-9]{7,8})*$/.test(v) || "Insert valid phone no"
            ];

            this.form.url.rule = [
                v => !v || /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(v) || "URL must be valid"
            ];

            let imagecheck = this.form.image.image === '';

            this.form.image.imgrule = [
                v => !imagecheck || 'Image is required',
            ];

            if (!this.select_all && this.form.method.selected === 1){
                this.form.subscriber.rule = [
                    v => !v.length == 0 || 'Field is required',
                ];
            }

            if (!this.select_all && this.form.subscriber.selected.length === 0 && this.form.nonsubscriberphone.item === ""){
                this.form.nonsubscriberphone.rule = [
                    v => !!v || "Please enter at least one phone number for subscriber and/or non subscriber"
                ];
            }

            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                self.ma.alertmodal = true;
                //self.ma.modalInfo = {id: 1, name: 2};
                self.ma.modal_action_type = flag;
                    //self.addRateCard();
                }
            });
        },

        async execFilter(type){
            if (type == 'operator'){
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'carpark'){
                await this.searchTimeOut();
            }

            if (type == 'subscriber_type'){
                this.form.subscriber.items = [];
                if (this.form.subscriber_type.selected === 1) {
                    this.form.subscriber.header.splice(1, 0, ...this.removed);
                }
                else if (this.form.subscriber_type.selected === 2) {
                    this.form.subscriber.header.splice(1, 2);
                }
                await this.searchTimeOut();
            }
        },

        async handleImageMain(v) {
            //console.log(v.name.toString().split('.').pop().toLowerCase())
            console.log('img', v);
            //let size = v.size / 1024 / 1024 < 5;

            if (v){
            this.form.image.btn_loading = true;
            const selectedImage = v; //get first file
            let base64img = await this.getBase64(selectedImage);
            if (base64img == undefined) base64img = '';

            this.form.image.image = base64img;
            this.form.image.btn_loading = false;
            this.imgValidate(this.form.method.selected);
            }

            else{
                await this.$nextTick(async () => {
                this.form.image.image = '';
                this.form.image.btn_loading = false;
                });
            }
        },

        async imgValidate(itm){
            if (itm === 1){
                this.imghint = "Horizontal: 600 Vertical: 300";
                this.bodyhint = "";
                let check = await this.getImageDimensions(this.form.image.image, 600, 300);
                if (!check){
                    this.form.image.image = '';
                    this.form.image.file = '';
                    this.form.image.imgrule = [
                        v => false || 'Please ensure image is 600 x 300',
                    ];
                }

                else{
                    this.form.image.imgrule = [];
                }
            }

            if (itm === 3){
                this.imghint = "Image size < 5mb";
                this.bodyhint = "Please only edit values in the {{}} area. Remain as it is if no changes.";
                if (this.form.image.image == '') return;
                
                let size = this.form.image.file.size / 1024 / 1024 < 5;
                if (!size){
                    this.form.image.image = '';
                    this.form.image.file = '';
                    this.form.image.imgrule = [
                        v => false || 'Please ensure image size is below 5mb',
                    ];
                }

                else{
                    this.form.image.imgrule = [];
                }
            }
        },

        getImageDimensions(file, w, h) {
            if (file != ''){
                let fact;
                return new Promise (function (resolved, rejected) {
                    var i = new Image()
                    i.onload = function(){
                        if (i.width !== w || i.height !== h){
                            fact = false;
                        }

                        else{
                            console.log('truth', 'true');
                            fact = true;
                        }

                        resolved(fact);
                    };

                    i.src = file;
                });
            }

            else{
                return true;
            }
        },

        getBase64(file) {
            if (file){
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            }
        },

        addDropFile(e) { this.form.image.file = e.dataTransfer.files[0]; },

        onScroll(e) {
            // debounce if scrolling fast
            this.timeout && clearTimeout(this.timeout);
        
            this.timeout = setTimeout(() => {
                const { scrollTop } = e.target;
                const rows = Math.ceil(scrollTop / this.rowHeight);

                this.start = rows + this.perPage > this.form.subscriber.items.length ?
                this.form.subscriber.items.length - this.perPage: rows;

                this.$nextTick(() => {
                e.target.scrollTop = scrollTop;
                });
            }, 20);
        },

        onConfirm(value){
            console.log(value);
            if (value == true){
                this.ma.confirmCreate = true;
                this.ma.alertmodal = false;
                if (this.form.method.selected === 1) this.addPushNotification();
                if (this.form.method.selected === 2) this.addSMS();
                if (this.form.method.selected === 3) this.addWhatsApp();
            }
        },

        onClosed(value){
            if (value == false){
                this.ma.alertmodal = value;
            }
        },

        selectAll(){
            if (this.select_all){
                this.form.subscriber.selected = [];
            }
        },

        removeSelectAll(){
            this.select_all = false;
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.getSubscriberFull(1);
          }, 800);
        },
    }
}
</script>